import React, { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Nav from "../components/UI/Nav/Nav"
import HeadImage from "../components/UI/HeadImage/HeadImage"
import NewsList from "../components/UI/NewsList/NewList"
import Footer from "../components/UI/Footer/Footer"
import Modal from "../components/UI/Modal/Modal"
import SEO from "../components/seo"
import { Container, Row, Col, Button } from "react-bootstrap"

const IndexPage = ({ data }) => {
  const [modalShow, setModalShow] = useState(false)

  return (
    <Container className="no-gutters" fluid>
      <SEO />
      <form
        name="contact"
        netlify="true"
        netlify-honeypot="bot-field"
        data-netlify="true"
        hidden
      >
        <input type="text" name="name" />
        <input type="text" name="telephone" />
        <input type="email" name="email" />
        <textarea name="comments"></textarea>
      </form>
      <Modal show={modalShow} handleClose={() => setModalShow(false)} />
      <Nav active="INICIO" />
      <Row>
        <HeadImage image={data.header.childImageSharp.fluid} height={450} black>
          <h1>Todo lo que requieres en protección radiológica</h1>
          <p>
            10 años de experiencia y equipo de última generación, para
            garantizar tu protección y de tus pacientes de acuerdo a los
            parámetros marcados por las normas oficiales vigentes y a los
            estándares internacionales
          </p>
          <Button
            className="btn-success btn-lg mt-3"
            onClick={() => setModalShow(true)}
          >
            ¡Solicita cotización!
          </Button>
        </HeadImage>
      </Row>
      <Row className="justify-content-center">
        <Col
          xs="12"
          sm="10"
          md="9"
          lg="6"
          style={{ maxWidth: "700px" }}
          className="d-flex align-items-center my-5"
        >
          <Row className="justify-content-center align-items-center">
            <h6 className="text-center">
              CONTAMOS CON ALIANZAS COMERCIALES PARA BRINDARTE EL MEJOR SERVICIO
            </h6>
            <Col xs="5" className="d-flex justify-content-center">
              <Img
                fluid={data.mirion.childImageSharp.fluid}
                alt="mirion-logo"
                className="mt-3 w-100"
              />
            </Col>
            <Col
              xs={{ span: 5, offset: 1 }}
              className="d-flex justify-content-center"
            >
              <Img
                fluid={data.eerms.childImageSharp.fluid}
                alt="eerms-logo"
                className="mt-3 w-100"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center section-gray">
        <Col
          xs="12"
          md="11"
          lg="10"
          className="d-flex align-items-center"
          style={{ maxWidth: "1100px" }}
        >
          <Row className="justify-content-center align-items-center my-5">
            <Col
              xs="8"
              sm={{ span: 4, offset: 1 }}
              className="d-flex justify-content-center"
            >
              <Img
                fluid={data.instadose.childImageSharp.fluid}
                alt="instadose"
                className="w-100"
              />
            </Col>
            <Col
              xs="10"
              sm={{ span: 5, offset: 1 }}
              className="mt-4 mt-sm-0 text-center text-sm-left"
            >
              <h2 className="green">
                MEDICIÓN PRECISA Y SEGUIMIENTO DE LA EXPOSICIÓN
              </h2>
              <p>
                La combinación de dosímetro de cámara de ionización / MOSFETT
                permite mediciones precisas con capacidades de auto lectura para
                la determinación inmediata de exposición
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Container id="news" style={{ maxWidth: "1500px" }}>
        <Row className="justify-content-center justify-content-sm-start align-items-center my-3">
          <NewsList news={data.prismic.allNewss.edges} />
        </Row>
      </Container>
      <Footer />
    </Container>
  )
}

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "index.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mirion: file(relativePath: { eq: "logo_mirion.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    eerms: file(relativePath: { eq: "logo_eerms.png" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    instadose: file(relativePath: { eq: "instadose.png" }) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    prismic {
      allNewss(sortBy: meta_firstPublicationDate_DESC) {
        edges {
          node {
            _meta {
              id
              type
            }
            title
            article
            image
            imageSharp {
              childImageSharp {
                fluid(maxWidth: 350, maxHeight: 200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
