import React from "react"

import { Col } from "react-bootstrap"
import NewsCard from "./NewsCard/NewsCard"

const NewsList = ({ news }) =>
  news.map(item => (
    <Col
      key={item.node._meta.id}
      xs="12"
      sm="6"
      lg="4"
      className="d-flex justify-content-center"
    >
      <NewsCard
        image={item.node.imageSharp.childImageSharp.fluid}
        title={item.node.title[0].text}
        summary={(item.node.article[0].text?.substring(0, 140) || "") + "..."}
        link={`/news/${item.node._meta.id}`}
      ></NewsCard>
    </Col>
  ))
export default NewsList
