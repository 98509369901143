import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Card } from "react-bootstrap"

import "./NewsCard.css"

const NewsCard = ({ image, title, summary, link }) => (
  <Link
    to={link}
    style={{ display: "flex", justifyContent: "center", width: "100%" }}
  >
    <Card className="news-card my-3">
      <Img className="card-img-top" fluid={image} />
      <Card.Body className="d-flex flex-column justify-content-between">
        <Card.Title className="d-flex align-items-center">
          <span>{title}</span>
        </Card.Title>
        <Card.Text>{summary}</Card.Text>
        <span className="link"> Ver más</span>
      </Card.Body>
    </Card>
  </Link>
)
export default NewsCard
